import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, BreakoutContentCard, BreakoutContentRow, DataTable, PageIntro, TextBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import doseModTable from "../../assets/dosing-mod-recommended-dosage-small-V1.svg"
import specificDoseModTable from "../../assets/dosing-mod-2-combination-small-V1.svg"
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Dose Modifications for Adverse Reactions to KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda dose modifications`,
    description: `Health care professionals may find information about dose modifications for adverse reactions to KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/dose-modifications/","@type":"MedicalWebPage","name":"Dose Modifications","description":"No dose reduction for KEYTRUDA is recommended."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/dose-modifications/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[34].jobCode;

const relatedContent = [
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/options/',
            label: 'Dosing'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/preparation-storage-administration/',
            label: 'Preparation, Storage & Administration'
        }
    }
]

const recommendedDosageModificationFootnotes = [
    {
        label: "a.",
        text: "Based on Common Terminology Criteria for Adverse Events (CTCAE), version 4.0."
    },
    {
        label: "b.",
        text: "Resume in patients with complete or partial resolution (Grades 0 to 1) after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating 	steroids or inability to reduce prednisone to 10&nbsp;mg per day or less (or equivalent) within 12 weeks of initiating steroids."
    },
    {
        label: "c.",
        text: "Recommended dosage modifications for liver enzyme elevations in patients treated with combination therapy with axitinib are shown below."
    },
    {
        label: "d.",
        text: "If AST and ALT are less than or equal to ULN at baseline, withhold or permanently discontinue KEYTRUDA based on recommendations for hepatitis with no liver involvement."
    }
];

const recommendedDosageModificationKeytrudaComboFootnotes = [
    {
        label: "e.",
        text: "Consider corticosteroid therapy."
    },
    {
        label: "f.",
        text: "Based on CTCAE, version 4.0. Consider rechallenge with a single drug or sequential rechallenge with both drugs after recovery. If rechallenging with axitinib, consider dose reduction as 	per the axitinib Prescribing Information."
    },
];

const recommendedDosageModificationKeytrudaComboDefinitions = "ALT = alanine aminotransferase; AST = aspartate aminotransferase; cHL = classical Hodgkin lymphoma; DRESS = drug rash with eosinophilia and systemic symptoms; PMBCL = primary mediastinal large B-cell lymphoma; SJS = Stevens-Johnson syndrome; TEN = toxic epidermal necrolysis; ULN = upper limit of normal."


const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-b-intro">
                    <PageHeader title="Dose Modifications" />
                    <PageSection bgColor='cloud' title="Dose Modifications" centerTitle={true}>
                        <PageIntro centered={true}>
                            {`Monitor patients regularly and encourage them to immediately report any changes in their health.`}
                        </PageIntro>
                        <BreakoutContentRow>
                            <BreakoutContentCard icon={() => <img src="/static/images/badge-iv.png" alt="" width={60} height={60} />} title="No dose reduction for KEYTRUDA is recommended." />
                            <BreakoutContentCard icon={() => <img src="/static/images/badge-clipboard.png" alt="" width={60} height={60} />} title="In general, withhold KEYTRUDA for severe (Grade 3) immune-mediated adverse reactions." />
                            <BreakoutContentCard icon={() => <img src="/static/images/badge-calendar.png" alt="" width={60} height={60} />} title="Permanently discontinue KEYTRUDA for:">
                                <ul>
                                    <li>Life-threatening (Grade 4) immune-mediated adverse reactions.</li>
                                    <li>Recurrent severe (Grade 3) immune-mediated reactions that require systemic immunosuppressive treatment.</li>
                                    <li>An inability to reduce corticosteroid dose to 10&nbsp;mg or less of prednisone or equivalent per day within 12 weeks of initiating steroids.</li>
                                </ul>
                            </BreakoutContentCard>
                        </BreakoutContentRow>
                        <TextBlock>
                            <p>Dosage modifications for KEYTRUDA for adverse reactions that require management that differs from these general guidelines are summarized below.</p>
                        </TextBlock>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                <PageSection>
                    <DataTable title="Recommended Dosage Modifications"
                        mobileImage={() => <img src={doseModTable} style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }} alt="" />}
                        desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../assets/dosing-mod-1-1032.png" alt="" />}
                        footnotes={recommendedDosageModificationFootnotes}
                    />
                    <DataTable
                        title="Recommended Specific Dosage Modifications for KEYTRUDA in Combination With Axitinib"
                        limitation="The following table represents dosage modifications that are different from those described previously for KEYTRUDA or in the Full Prescribing Information for the drug administered in combination."
                        mobileImage={() => <img src={specificDoseModTable} style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }} alt="" />}
                        desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../assets/dosing-mod-2-1032.png" alt="" />}
                        footnotes={recommendedDosageModificationKeytrudaComboFootnotes}
                        definitions={recommendedDosageModificationKeytrudaComboDefinitions} />
                </PageSection>
                {/* </TemplateColumn> */}

                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection bgColor='keytrudaGreen'>
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
